@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.App {
  font-family: sans-serif;
  text-align: center;

}

.img {
  width:100%;
  height: 300px;
  object-fit: cover;
}

a.underline {
  text-decoration: none;
  color: black;
}
a.underline:hover {
  text-decoration: none;
  color: rgb(101, 25, 25);
  zoom:102%;
}

.conacts-container {
  margin: auto;
}
.footer{
  background-color: #3e5264;
}
.font{
  font-family: 'Montserrat', sans-serif;
  
}
.icons {
  width:30px;
  height: auto;
  margin-top: 2.5rem;
  color:aliceblue;
}